
.audit-control-panel {
  text-align: right;
}

.audit-control-panel a, .audit-control-panel button {
  margin: 5px;
}

.all-good {
  text-align: center;
}

.scoringbox {
  height: 300px;
  padding: 15px;
}

.scoringbox span {
  display: inline-block;
  vertical-align: top;
}

.scoringdetails div {
  vertical-align: top;
  margin: 0 0 0 10px;
}

.scoringdetails div.bold {
  font-weight: bold;
}

.scoring-box-tagpresence {
  border-top-color: #6CC16B;
}

.scoring-box-loadtime {
  border-top-color: #6BC1BC;
}

.scoring-box-statuscode {
  border-top-color: #FFB56B;
}

.scoring-box-seo-analyze {
  border-top-color: #B242B6;
}

.scoring-box-html-missing-tags {
  border-top-color: #FD2D2D;
}

.scoring-box-seo-page-load {
  border-top-color: #6BC1BC;
}

.scoring-box-page-size {
  border-top-color: #FFB56B;
}

.scoring-box-jserrors {
  border-top-color: #B242B6;
}

.scoring-box-tagduplicate {
  border-top-color: #fd2d2d;
}

.bluebold {
  color: #3ac3fa;
  font-size: 36px;
  font-weight: bold;
  line-height: 20px;
  font-family: helvetica;
  margin: 10px 0 0 10px;
}

.scoringdetails div:nth-child(3) {
  font-weight: bold;
  margin: 30px 0 0 10px;
}

.donutchart {
  text-align: center;
}

.donutchart .box-header {
  margin: 0 0 10px 0;
}

.donutchart .knob-label .green { color: #7fca8f; }
.donutchart .knob-label .red { color: #f68a8a; }

.donutchart .knob-label div > span:first-child {
  width: 20px;
  margin: 0 20px 0 0;
  text-align: right;
  font-weight: bold;
}

.donutchart .knob-label div > span:last-child {
  text-align: left;
  width: 80px;
}

#scoring-line-chart {
  height: 200px;
}

.scoring-minibox .scoringbox {
  text-align: center;
  height: 260px;
}

.scoring-minibox .donutchart {
  height: 100px;
}

.form-audit .scheduled_details {
  display: none;
}

.audit-item .info-box {
  min-height: 125px;
}

.audit-report-columns {
  cursor: pointer;
  user-select: none;
}

.audit-report-columns > div:hover {
  border: 1px solid #c3b4ff;
  border-radius: 2px;
}

.audit-report-columns .selected, .audit-report-columns .selected:hover {
  border: 2px solid #1f0d67;
  border-radius: 2px;
}

.audit-report-columns .good {
  background: #edffed;
}

.audit-report-columns .warning, .dataTable > tbody > tr.warning {
  background: #feffea;
}

.audit-report-columns .error, .dataTable > tbody > tr.error {
  background-color: #ffeaea;
}

.audit-report-table .dataTable {
  table-layout: fixed;
}

.audit-report-table .dataTable tr {
  width: 100%;
}

.audit-report-table .dataTable td {
  cursor: copy;
  overflow: hidden;
}

.audit-item .info-box-content {
}

.audit-item .info-box-icon {
  height: 125px;
  width: 200px;
  margin-right: 10px;
}

.websitepreview img {
  box-shadow: 1px 1px 4px #888888;
}

.img-full-height {
  height: 100%;
}

.property-icon {
  line-height: 0px;
}


/* Developer CSS */

.btn-app.dev {
  min-width: 20px;
  height: 20px;
  font-size: 10px;
  padding: 1px 2px 33px 2px;
  margin: 1px;
  float: right;
}

.treeview-menu .fa-circle-o-notch.in-progress {
  width: auto;
  -webkit-animation:spin 1s linear infinite;
  -moz-animation:spin 1s linear infinite;
  animation:spin 1s linear infinite;
  margin-right: 10px;
}

.audit-schedule-list-title {
  margin-top: 8px;
  margin-right: -15px;
}

.audit-schedule-list {

}

.audit-schedule-list select {
  float: right;
}


@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
