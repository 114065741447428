.noty_layout_mixin, #noty_layout__top, #noty_layout__topLeft, #noty_layout__topCenter, #noty_layout__topRight, #noty_layout__bottom, #noty_layout__bottomLeft, #noty_layout__bottomCenter, #noty_layout__bottomRight, #noty_layout__center, #noty_layout__centerLeft, #noty_layout__centerRight {
  position: fixed;
  margin: 0;
  padding: 0;
  z-index: 9999999;
  transform: translateZ(0) scale(1, 1);
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  filter: blur(0);
  -webkit-filter: blur(0);
  max-width: 90%;
}

#noty_layout__top {
  top: 0;
  left: 5%;
  width: 90%;
}

#noty_layout__topLeft {
  top: 20px;
  left: 20px;
  width: 325px;
}

#noty_layout__topCenter {
  top: 5%;
  left: 50%;
  width: 325px;
  transform: translate(calc(-50% - .5px)) translateZ(0) scale(1, 1);
}

#noty_layout__topRight {
  top: 20px;
  right: 20px;
  width: 325px;
}

#noty_layout__bottom {
  bottom: 0;
  left: 5%;
  width: 90%;
}

#noty_layout__bottomLeft {
  bottom: 20px;
  left: 20px;
  width: 325px;
}

#noty_layout__bottomCenter {
  bottom: 5%;
  left: 50%;
  width: 325px;
  transform: translate(calc(-50% - .5px)) translateZ(0) scale(1, 1);
}

#noty_layout__bottomRight {
  bottom: 20px;
  right: 20px;
  width: 325px;
}

#noty_layout__center {
  top: 50%;
  left: 50%;
  width: 325px;
  transform: translate(calc(-50% - .5px), calc(-50% - .5px)) translateZ(0) scale(1, 1);
}

#noty_layout__centerLeft {
  top: 50%;
  left: 20px;
  width: 325px;
  transform: translate(0, calc(-50% - .5px)) translateZ(0) scale(1, 1);
}

#noty_layout__centerRight {
  top: 50%;
  right: 20px;
  width: 325px;
  transform: translate(0, calc(-50% - .5px)) translateZ(0) scale(1, 1);
}

.noty_progressbar {
  display: none;
}

.noty_has_timeout.noty_has_progressbar .noty_progressbar {
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 3px;
  width: 100%;
  background-color: #646464;
  opacity: 0.2;
  filter: alpha(opacity=10);
}

.noty_bar {
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate(0, 0) translateZ(0) scale(1, 1);
  transform: translate(0, 0) scale(1, 1);
  -webkit-font-smoothing: subpixel-antialiased;
  overflow: hidden;
}

.noty_effects_open {
  opacity: 0;
  transform: translate(50%);
  animation: noty_anim_in 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  animation-fill-mode: forwards;
}

.noty_effects_close {
  animation: noty_anim_out 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  animation-fill-mode: forwards;
}

.noty_fix_effects_height {
  animation: noty_anim_height 75ms ease-out;
}

.noty_close_with_click {
  cursor: pointer;
}

.noty_close_button {
  position: absolute;
  top: 2px;
  right: 2px;
  font-weight: bold;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  cursor: pointer;
  transition: all .2s ease-out;
}

.noty_close_button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.noty_modal {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 10000;
  opacity: .3;
  left: 0;
  top: 0;
}

.noty_modal.noty_modal_open {
  opacity: 0;
  animation: noty_modal_in .3s ease-out;
}

.noty_modal.noty_modal_close {
  animation: noty_modal_out .3s ease-out;
  animation-fill-mode: forwards;
}

@keyframes noty_modal_in {
  100% {
    opacity: .3;
  }
}

@keyframes noty_modal_out {
  100% {
    opacity: 0;
  }
}

@keyframes noty_modal_out {
  100% {
    opacity: 0;
  }
}

@keyframes noty_anim_in {
  100% {
    transform: translate(0);
    opacity: 1;
  }
}

@keyframes noty_anim_out {
  100% {
    transform: translate(50%);
    opacity: 0;
  }
}

@keyframes noty_anim_height {
  100% {
    height: 0;
  }
}

.noty_theme__mint.noty_bar {
  margin: 4px 0;
  overflow: hidden;
  border-radius: 2px;
  position: relative;
}

.noty_theme__mint.noty_bar .noty_body {
  padding: 10px;
  font-size: 14px;
}

.noty_theme__mint.noty_bar .noty_buttons {
  padding: 10px;
}

.noty_theme__mint.noty_type__alert,
.noty_theme__mint.noty_type__notification {
  background-color: #fff;
  border-bottom: 1px solid #D1D1D1;
  color: #2F2F2F;
}

.noty_theme__mint.noty_type__warning {
  background-color: #FFAE42;
  border-bottom: 1px solid #E89F3C;
  color: #fff;
}

.noty_theme__mint.noty_type__error {
  background-color: #DE636F;
  border-bottom: 1px solid #CA5A65;
  color: #fff;
}

.noty_theme__mint.noty_type__info,
.noty_theme__mint.noty_type__information {
  background-color: #7F7EFF;
  border-bottom: 1px solid #7473E8;
  color: #fff;
}

.noty_theme__mint.noty_type__success {
  background-color: #AFC765;
  border-bottom: 1px solid #A0B55C;
  color: #fff;
}

.audit-control-panel {
  text-align: right;
}

.audit-control-panel a, .audit-control-panel button {
  margin: 5px;
}

.all-good {
  text-align: center;
}

.scoringbox {
  height: 300px;
  padding: 15px;
}

.scoringbox span {
  display: inline-block;
  vertical-align: top;
}

.scoringdetails div {
  vertical-align: top;
  margin: 0 0 0 10px;
}

.scoringdetails div.bold {
  font-weight: bold;
}

.scoring-box-tagpresence {
  border-top-color: #6CC16B;
}

.scoring-box-loadtime {
  border-top-color: #6BC1BC;
}

.scoring-box-statuscode {
  border-top-color: #FFB56B;
}

.scoring-box-seo-analyze {
  border-top-color: #B242B6;
}

.scoring-box-html-missing-tags {
  border-top-color: #FD2D2D;
}

.scoring-box-seo-page-load {
  border-top-color: #6BC1BC;
}

.scoring-box-page-size {
  border-top-color: #FFB56B;
}

.scoring-box-jserrors {
  border-top-color: #B242B6;
}

.scoring-box-tagduplicate {
  border-top-color: #fd2d2d;
}

.bluebold {
  color: #3ac3fa;
  font-size: 36px;
  font-weight: bold;
  line-height: 20px;
  font-family: helvetica;
  margin: 10px 0 0 10px;
}

.scoringdetails div:nth-child(3) {
  font-weight: bold;
  margin: 30px 0 0 10px;
}

.donutchart {
  text-align: center;
}

.donutchart .box-header {
  margin: 0 0 10px 0;
}

.donutchart .knob-label .green {
  color: #7fca8f;
}

.donutchart .knob-label .red {
  color: #f68a8a;
}

.donutchart .knob-label div > span:first-child {
  width: 20px;
  margin: 0 20px 0 0;
  text-align: right;
  font-weight: bold;
}

.donutchart .knob-label div > span:last-child {
  text-align: left;
  width: 80px;
}

#scoring-line-chart {
  height: 200px;
}

.scoring-minibox .scoringbox {
  text-align: center;
  height: 260px;
}

.scoring-minibox .donutchart {
  height: 100px;
}

.form-audit .scheduled_details {
  display: none;
}

.audit-item .info-box {
  min-height: 125px;
}

.audit-report-columns {
  cursor: pointer;
  user-select: none;
}

.audit-report-columns > div:hover {
  border: 1px solid #c3b4ff;
  border-radius: 2px;
}

.audit-report-columns .selected, .audit-report-columns .selected:hover {
  border: 2px solid #1f0d67;
  border-radius: 2px;
}

.audit-report-columns .good {
  background: #edffed;
}

.audit-report-columns .warning, .dataTable > tbody > tr.warning {
  background: #feffea;
}

.audit-report-columns .error, .dataTable > tbody > tr.error {
  background-color: #ffeaea;
}

.audit-report-table .dataTable {
  table-layout: fixed;
}

.audit-report-table .dataTable tr {
  width: 100%;
}

.audit-report-table .dataTable td {
  cursor: copy;
  overflow: hidden;
}

.audit-item .info-box-icon {
  height: 125px;
  width: 200px;
  margin-right: 10px;
}

.websitepreview img {
  box-shadow: 1px 1px 4px #888888;
}

.img-full-height {
  height: 100%;
}

.property-icon {
  line-height: 0px;
}

/* Developer CSS */
.btn-app.dev {
  min-width: 20px;
  height: 20px;
  font-size: 10px;
  padding: 1px 2px 33px 2px;
  margin: 1px;
  float: right;
}

.treeview-menu .fa-circle-o-notch.in-progress {
  width: auto;
  -webkit-animation: spin 1s linear infinite;
  -moz-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  margin-right: 10px;
}

.audit-schedule-list-title {
  margin-top: 8px;
  margin-right: -15px;
}

.audit-schedule-list select {
  float: right;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
